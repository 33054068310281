import { Box, Stack, Typography } from "@mui/material";
import LocalImage from "../../../../components/LocalImage";

const RaidBoostItemLockedCard = ({
  boostItem
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      p={1}
      height="calc(100% - 16px)"
      bgcolor="#586064"
      borderRadius="8px"
      sx={{
        opacity: 0.8
      }}
    >
      <Box display="flex" gap={1}>
        <LocalImage
          src={`img/team/lock-closed.svg`}
          alt={`Arrow Unlocked`}
          loading="lazy"
          width="72px"
          height="72px"
          style={{
            borderRadius: "4px",
            border: "0.832px solid rgba(255, 255, 255, 0.51)"
          }}
        />
        <Stack spacing={0.5}>
          <Typography
            color="#FFF"
            fontSize={14}
            fontWeight={700}
            lineHeight="16px"
            textTransform="uppercase"
            sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
          >
            {boostItem?.title}
          </Typography>
          <Typography
            color="#FFF"
            fontSize={14}
            fontWeight={400}
            lineHeight="16px"
            textTransform="uppercase"
            sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
          >
            Level {boostItem?.level || 0} of {boostItem?.maxLevel || 0}
          </Typography>
        </Stack>
      </Box>
      <Box borderTop="1px solid #FFF" borderBottom="1px solid #FFF">
        <Typography
          color="#FFF"
          fontSize={14}
          fontWeight={500}
          sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
        >
          Not Unlocked
        </Typography>
      </Box>
      <Box>
        <Typography
          color="#FFF"
          fontSize={14}
          fontWeight={400}
          lineHeight="16px"
          sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
        >
          {boostItem?.description}
        </Typography>
      </Box>
    </Box>
  );
}

export default RaidBoostItemLockedCard;
