import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import { Heading1 } from '../../components/Heading';
import Footer from '../../components/Footer/FooterMenu';
import { CustomButton } from '../../components/Buttons';
import Container from '../../components/Layout/Container';
import DetailsSection from '../SeasonRecap/Details';
import VideoItem from './Video';

import { useUserDetails } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';
import { sortObjectArray } from '../../utils';

const VideoList = () => {
  const { username } = useParams();
  const {
    data: { data: userDetails, isLoading: isLoadingUserDetails },
  } = useUserDetails(username, null);
  const [isRecapLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const isLoading = !userDetails || isLoadingUserDetails || isRecapLoading;
  const profileAggregates = userDetails?.results?.profile_aggregates;
  const userProfileInfo = userDetails?.results?.user_profile_info
    ? userDetails.results.user_profile_info[0]
    : {};

  useEffect(() => {
    if (!!userDetails?.results?.user_id) {
      callBackbonePostApi({
        action: "getSessionDataFromLast45DaysForUser",
        userId: userDetails?.results?.user_id
      }, '')
        .then((data) => {
          setIsLoading(false);

          if (data?.status === 200 && data?.data) {
            setData(data?.data);
          }
        })
        .catch(() => {
          setIsLoading(true);
        });
    }
  }, [userDetails]);

  const userId = userDetails?.results?.user_id;
  const isPublicProfile = userProfileInfo.publicProfileAllowed;
  const gymInfo = { id: userProfileInfo.gym_id, name: userProfileInfo.gym_name };
  const userImage = userProfileInfo.profile_pic_url;
  const xpTitle = userDetails?.results?.xp_title ?? '';
  const inviteState = userDetails?.results?.invite_button_state;
  const currentLeague = parseInt(userProfileInfo.league?.N ?? 0);
  const displayTrophyCount = userProfileInfo.trophies;
  const achievements = userDetails?.results?.recent_achievements;
  const videos = Object.keys(data || {}).map(id => ({
    calories: data[id].Calories,
    chal_name: data[id].ChalName || "",
    chal_score: data[id].ChalScore,
    isRaidBattle: data[id].isRaidBattle,
    match_outcome: data[id].matchOutcome || "",
    rep_count: data[id].RepCount,
    set_count: data[id].SetCount,
    slice_count: data[id].SliceCount,
    starttime: data[id].StartTime,
    total_volume: data[id].TotalVolume || 0,
    total_work: data[id].TotalWork,
    trophies_earned: data[id].UserEarnedTrophies || 0,
    user_champions_deployed: data[id].UserChampionsDeployed,
    video_link: data[id].cloudFrontLink,
    user_score: data[id].UserScore,
    raid_points_earned: data[id].pointsDelta,
    challenger_reached: data[id].challengerReached
  }));

  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community', link: '/' },
    { title: 'Meridian Gym', link: '/gym/2/Meridian' },
    { title: 'Members', link: '/' },
    { title: username.charAt(0).toUpperCase() + username.slice(1).toLowerCase(), link: `/member/${username}` },
    { title: 'Game Videos' },
  ];

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Videos`}
        description={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Videos`}
      />
      {isLoading ? (
        <Loading fullscreen />
      ) : (
        <>
          <DetailsSection
            username={username}
            userImage={userImage}
            userId={userId}
            xpTitle={xpTitle}
            currentLeague={currentLeague}
            displayTrophyCount={displayTrophyCount}
            details={profileAggregates}
            achievements={achievements}
            gymInfo={gymInfo}
            isPublicProfile={isPublicProfile}
            inviteState={inviteState}
            breadcrumbs={breadcrumbs}
          />
          <Container>
            <Heading1 sx={{
              marginTop: 6,
              marginBottom: 6,
              paddingLeft: {
                xs: 2,
                tl: 0,
              },
              paddingRight: {
                xs: 2,
                tl: 0,
              },
            }}>Latest Gameplay VideoS</Heading1>
          </Container>
          {sortObjectArray(videos, 'starttime', 'desc').map((video, index) => <VideoItem data={video} order={index} key={`video-${index}`} />)}
          <Box sx={{
            marginTop: 9,
            marginBottom: 35,
            textAlign: "center",
            paddingLeft: {
              xs: 2,
              tl: 0,
            },
            paddingRight: {
              xs: 2,
              tl: 0,
            },
          }}>
            <Typography sx={{
              fontSize: "24px",
              width: {
                xs: "auto",
                tl: "455px",
              },
              margin: "auto"
            }}>
              {videos.length > 0 ? "NOTE: Gameplay videos are automatically deleted after a certain number of days." : "There are no gameplay videos to show. The member may have chosen to hide gameplay videos, or the video has expired."}
            </Typography>
            <CustomButton href={`/member/${username}`} sx={{ marginTop: 9 }}>
              View Profile Main Page
            </CustomButton>
          </Box>
        </>
      )}
      <Footer />
    </Box>
  );
};

export default VideoList;
