import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import Container from '../../../components/Layout/Container';
import { Heading2 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';
import { formatNumber, formatPercentageNumber } from '../../../utils';
import S3Avatar from '../../../components/S3Avatar';
import { BBVR_FILE_URL } from '../../../config/constants';
import { useCallback } from 'react';

const PreRaidTeamRecapOverview = ({
  config,
  boostInfo,
  boostTokenInfo,
  season,
  month
}) => {
  const minionPlayerLevelIncrease = boostInfo.find(i => i.shortCode === "MinionPlayerLevelIncrease") || {};
  const minionChallengerLevelReduction = boostInfo.find(i => i.shortCode === "MinionChallengerLevelReduction") || {};
  const unlockedBoostItems = boostInfo.filter(i => i.unlocked) || [];

  // The minion levels are zero-indexed
  const getLevelForVision = useCallback((level) => {
    const parsedLevel = parseInt(level) || 0;
    return parsedLevel + 1;
  }, [])

  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading2 sx={{ px: { xs: 2, md: 0 } }}>SEASON {season}, MONTH {month} PRE-RAID RECAP!</Heading2>
      <Typography sx={{
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: 2,
        px: { xs: 2, md: 0 }
      }}>
        {moment.unix(config?.[`Month${month}StartTimestamp`]).local().format('MMM D, YYYY')} - {moment.unix(config?.[`Month${month}RaidTimestamp`]).local().format('MMM D, YYYY')}
      </Typography>
      <Grid container spacing={1} sx={{
        justifyContent: "center",
        marginTop: 6,
        padding: "25px 16px 25px 16px",
        backgroundColor: "#141516"
      }}>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" sx={{ alignItems: "center" }}>
          {getLevelForVision(minionPlayerLevelIncrease?.level) && (
            <S3Avatar
              src={`${BBVR_FILE_URL}/MinionImages/592x800/WEBP/Level${getLevelForVision(minionPlayerLevelIncrease?.level)}.webp`}
              width="105px"
              height="150px"
              style={{
                filter: "drop-shadow(0px 0px 10px #94FFC2)"
              }}
            />
          )}
          <Box ml={1} textAlign="left">
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
            }}>Your Raid Minion</Typography>
            <Heading2>LEVEL {getLevelForVision(minionPlayerLevelIncrease?.level)}</Heading2>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: "2px",
            }}>{getLevelForVision(minionPlayerLevelIncrease?.level)} / {getLevelForVision(minionPlayerLevelIncrease?.maxLevel)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" sx={{ alignItems: "center" }}>
          <LocalImage src="img/team/boost_tokens.png" alt="Boost Tokens" loading="lazy" height="150px" />
          <Box ml={1} textAlign="left">
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
            }}>Boost Tokens Earned</Typography>
            <Heading2>{formatPercentageNumber(boostTokenInfo?.teamBoostTokens, boostTokenInfo?.totalBoostTokensPossible)}</Heading2>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: "2px",
            }}>{formatNumber(boostTokenInfo?.teamBoostTokens)} / {formatNumber(boostTokenInfo?.totalBoostTokensPossible)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" sx={{ alignItems: "center" }}>
          <LocalImage src="img/team/boost_items.png" alt="Boost Items" loading="lazy" height="150px" />
          <Box ml={1} textAlign="left">
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
            }}>Boost Items Unlocked</Typography>
            <Heading2>{formatNumber(unlockedBoostItems?.length)}</Heading2>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: "2px",
            }}>{formatNumber(unlockedBoostItems.length)} / {formatNumber(boostInfo?.length)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" sx={{ alignItems: "center" }}>
          {getLevelForVision(minionChallengerLevelReduction?.level) && (
            <S3Avatar
              src={`${BBVR_FILE_URL}/MinionImages/592x800/WEBP/Level${getLevelForVision(minionChallengerLevelReduction?.level)}.webp`}
              width="105px"
              height="150px"
              style={{
                filter: "drop-shadow(0px 0px 10px #94FFC2)"
              }}
            />
          )}
          <Box ml={1} textAlign="left">
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
            }}>Challenger's Minion</Typography>
            <Heading2>LEVEL {getLevelForVision(minionChallengerLevelReduction?.level)}</Heading2>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: "2px",
            }}>{getLevelForVision(minionChallengerLevelReduction?.level)} / {getLevelForVision(minionChallengerLevelReduction?.maxLevel)}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PreRaidTeamRecapOverview;
