import PreRaidGymRecap from "./PreRaid";

const GymRecap = ({ type }) => {
  return type === 'pre-raid' ? (
    <PreRaidGymRecap />
  ) : (
    <></>
  )
}

export default GymRecap;
