import { Typography } from "@mui/material";
import { Heading1 } from "../../../../components/Heading";
import Container from "../../../../components/Layout/Container";
import moment from "moment";

const SeasonDetail = ({
  config,
  season,
  month
}) => {
  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1 sx={{ px: { xs: 2, md: 0 } }}>SEASON {season}, MONTH {month} PRE-RAID RECAP!</Heading1>
      <Typography sx={{
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: 2,
        px: { xs: 2, md: 0 }
      }}>
        {moment.unix(config?.[`Month${month}StartTimestamp`]).local().format('MMM D, YYYY')} - {moment.unix(config?.[`Month${month}RaidTimestamp`]).local().format('MMM D, YYYY')}
      </Typography>
    </Container>
  )
}

export default SeasonDetail;
