import { Box, Stack, Typography } from "@mui/material";
import { Textfit } from "react-textfit";
import LocalImage from "../../../components/LocalImage";
import PreRaidRecapPerformerItem from "./PerformerItem";
import { sortBy } from "lodash";

const PreRaidRecapQuestItem = ({
                                   members,
                                   quest
                               }) => {
    const filteredPerformers = (quest?.allUserQuestProgress || []).filter(item => item?.point !== 0);
    const sortedPerformers = sortBy(filteredPerformers, ['point']).reverse().slice(0, 3);
    const memberInfo = (userId) => {
        return members.find(member => member?.userId === userId) || {};
    }

    return (
        <Box>
            <Box
                borderRadius="10px"
                border="1px solid #FFF"
                padding="12px 16px"
                sx={{
                    background: "linear-gradient(180deg, #052B8E 0%, #276DCC 36.98%, #5EAFFE 58.39%, #1547A8 100%)",
                    boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset"
                }}
            >
                <Stack sx={{
                    height: "28px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1px 3px 1px 2px",
                    borderRadius: "8px",
                    background: "rgba(0, 0, 0, 0.30)"
                }}>
                    <Textfit
                        mode="single"
                        forceSingleModeWidth={false}
                        min={12}
                        max={26}
                        style={{
                            fontFamily: 'Futura',
                            textShadow: "0px 1px 3px #000",
                            fontWeight: 450,
                            width: "100%",
                            textAlign: "center",
                            lineHeight: "26px",
                            textTransform: "uppercase"
                        }}
                    >{quest?.title}</Textfit>
                </Stack>
                <Stack p={1.5} alignItems="center">
                    <LocalImage
                        src={`img/team/Flame Tower.png`}
                        alt={`Flame Tower`}
                        loading="lazy"
                        width="96px"
                        height="96px"
                    />
                </Stack>
            </Box>
            <Box py={2.5} display="flex" flexDirection="column" gap={1.5}>
                {sortedPerformers.length > 0 ? (
                    sortedPerformers.map((performer, index) => (
                        <PreRaidRecapPerformerItem
                            key={performer?.userId}
                            user={performer}
                            rank={index + 1}
                            memberInfo={memberInfo(performer?.userId)}
                        />
                    ))
                ) : (
                    <Typography
                        sx={{
                            fontFamily: "Futura",
                            fontSize: "36px",
                            fontWeight: 450,
                            textShadow: "0px 1px 3px #000",
                            letterSpacing: "0.5px",
                            color: "rgba(255, 255, 255, 0.85)",
                            textAlign: "center",
                            padding: "8px 0"
                        }}
                    >
                        No team progress on this quest
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

export default PreRaidRecapQuestItem;