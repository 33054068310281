import { Box, Stack, Typography } from '@mui/material';

const CaptainMark = ({ sx, size }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: size ? { xs: 16, tl: 21 } : { xs: 16, tl: 32 },
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        opacity: 0.8,
        background:
          'linear-gradient(132deg, #0099CD -10.92%, #362983 44.05%, #E4007D 82%, #E31F21 119.96%)',
        px: { xs: 1, tl: 2 },
        boxSizing: 'border-box',
        ...sx,

        '& svg': {
          width: { xs: 6, tl: 10 },
          height: { xs: 10, tl: 17 },
        },
      }}
    >
      {size ? (
        <Stack alignItems="center" width="100%">
          <Typography fontSize={16}>Captain</Typography>
        </Stack>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" fill="none">
          <path
            d="M9.62562 11.376L9.35847 16.008C8.41131 16.416 7.80416 16.584 6.75986 16.584C2.09693 16.584 0.105469 12.432 0.105469 8.352C0.105469 3.96 2.38836 0 7.26987 0C8.14417 0 8.62989 0.0719991 9.43133 0.407999V4.968C8.84846 4.368 8.28988 4.008 7.41558 4.008C5.10841 4.008 4.57411 6.456 4.57411 8.28C4.57411 10.056 5.10841 12.408 7.34273 12.408C8.28988 12.408 8.94561 11.976 9.62562 11.376Z"
            fill="white"
          />
        </svg>
      )}
    </Box>
  );
};

export default CaptainMark;
