import { Box, Grid, Typography } from "@mui/material";
import { Heading2 } from "../../../components/Heading";
import Container from "../../../components/Layout/Container";
import RaidBoostItemUnlockedCard from "./UnlockedCard";
import RaidBoostItemLockedCard from "./LockedCard";
import LocalImage from "../../../components/LocalImage";
import { formatNumber } from "../../../utils";

const PreRaidTeamRecapArenaRaidBoostItems = ({
  boostInfo,
  boostTokenInfo
}) => {
  const unlockedItems = boostInfo.filter(item => item.unlocked);
  const lockedItems = boostInfo.filter(item => !item.unlocked);

  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading2 sx={{ px: { xs: 2, md: 0 } }}>THIS MONTH’S ARENA RAID BOOST ITEMS</Heading2>
      <Typography mt={1.5} fontSize={{ xs: 14, tl: 16, md: 20, xl: 24 }} lineHeight="32px" sx={{ px: { xs: 2, md: 0 } }}>
        Boost Items are perks that the entire team gets for this <br />month’s Arena Raid!
      </Typography>

      <Grid container mt={3} spacing={2} sx={{ px: { xs: 2, md: 0 } }}>
        {unlockedItems.map(boostItem => (
          <Grid key={boostItem?.shortCode} item xs={12} ip={6} lg={3}>
            <RaidBoostItemUnlockedCard boostItem={boostItem} />
          </Grid>
        ))}
        {lockedItems.map(boostItem => (
          <Grid key={boostItem?.shortCode} item xs={12} ip={6} lg={3}>
            <RaidBoostItemLockedCard boostItem={boostItem} />
          </Grid>
        ))}
      </Grid>

      <Box display="flex" mt={6} gap={3} sx={{ px: { xs: 2, md: 0 } }}>
        <Box display="flex" gap={1} alignItems="center">
          <LocalImage src="img/team/boost_tokens.png" alt="Boost Tokens" loading="lazy" height="52px" />
          <Typography
            color="#F1F1F1"
            fontSize={14}
            lineHeight="22px"
            sx={{ textShadow: "0px 4px 70px #000" }}
          >
            BOOST TOKENS EARNED: <br />{formatNumber(boostTokenInfo?.teamBoostTokens)} / {formatNumber(boostTokenInfo?.totalBoostTokensPossible)}
          </Typography>
        </Box>
        <Box display="flex" gap={1} alignItems="center">
          <LocalImage src="img/team/boost_items.png" alt="Boost Tokens" loading="lazy" height="52px" />
          <Typography
            color="#F1F1F1"
            fontSize={14}
            lineHeight="22px"
            sx={{ textShadow: "0px 4px 70px #000" }}
          >
            BOOST ITEMS UNLOCKED: <br />{formatNumber(unlockedItems.length)} / {formatNumber(boostInfo?.length)}
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default PreRaidTeamRecapArenaRaidBoostItems;
