import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { getUser } from '../utils/cookie';

const backboneApi = axios.create({
  baseURL: process.env.REACT_APP_BACKBONE_API_URL,
});

backboneApi.interceptors.request.use((config) => {
  const user = getUser();

  if (user?.token) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }
  

  config.headers['x-api-key'] = process.env.REACT_APP_BACKBONE_API_KEY;

  return config;
}, (error) => {
  console.error("Request Error: ", error);
  return Promise.reject(error);
});

backboneApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Error Object: ", error);  // Log the entire error object

    if (error.response) {
      console.error("Response Status: ", error.response.status);
      console.error("Response Data: ", JSON.stringify(error.response.data, null, 2));
    } else if (error.request) {
      console.error("Request Object: ", error.request);  // Log the request object if no response is received
    } else {
      console.error("Error Message: ", error.message);
    }

    if (error.response?.status === 401 && error.response?.data === 'Unauthorized') {
      enqueueSnackbar('Unauthorized: Your session expired. Please reload the web app.', {
        variant: 'error',
      });

      window.stop();
    }

    return Promise.reject(error.response || 'Something went wrong');
  }
);

export default backboneApi;
