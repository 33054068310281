import { Grid, Typography } from "@mui/material";
import { Heading1 } from "../../../../components/Heading";
import Container from "../../../../components/Layout/Container";
import RaidBoostItemUnlockedCard from "./UnlockedCard";
import RaidBoostItemLockedCard from "./LockedCard";

const PreRaidArenaRaidBoostItems = ({
  boostInfo
}) => {
  const unlockedItems = Object.values(boostInfo).filter(item => item.unlocked !== false);
  const lockedItems = Object.values(boostInfo).filter(item => item.unlocked === true);

  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1 sx={{ px: { xs: 2, md: 0 } }}>THIS MONTH’S ARENA RAID BOOST ITEMS</Heading1>
      <Typography mt={1.5} fontSize={{ xs: 14, tl: 16, md: 20, xl: 24 }} lineHeight="32px" sx={{ px: { xs: 2, md: 0 } }}>
        Boost Items are perks that the entire team gets for this <br />month’s Arena Raid!
      </Typography>

      <Grid container mt={3} spacing={2} sx={{ px: { xs: 2, md: 0 } }}>
        {unlockedItems.map(boostItem => (
          <Grid key={boostItem?.shortCode} item xs={12} ip={6} lg={3}>
            <RaidBoostItemUnlockedCard boostItem={boostItem} />
          </Grid>
        ))}
        {lockedItems.map(boostItem => (
          <Grid key={boostItem?.shortCode} item xs={12} ip={6} lg={3}>
            <RaidBoostItemLockedCard boostItem={boostItem} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default PreRaidArenaRaidBoostItems;
