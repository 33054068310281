import { Box, Grid, Typography } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Link } from 'react-router-dom';

import Container from '../../../../components/Layout/Container';
import { Heading4, Heading5 } from '../../../../components/Heading';
import { CustomButton } from '../../../../components/Buttons';

const Addresses = ({ address, contact, openHours }) => {
    const street = address?.Address;
    const city = address?.City;
    const state = address?.State;
    const zipCode = address?.ZipCode;
    const email = contact?.Email;
    const phone = contact?.Phone;

    return (
        <Container>
            <Grid container mt={4} mx={{ xs: 2, tl: 0 }} width={{ xs: 'calc(100% - 32px)', tl: 'auto' }}>
                <Grid item xs={12} tl={3}>
                    <Box
                        sx={{
                            background: '#2C2F31',
                            width: { xs: '100%', tl: 145, lg: 296 },
                            height: { xs: 204, tl: 102, lg: 191 },
                            display: 'flex',
                            alignItems: 'center',

                            '& iframe': {
                                border: 0,
                            },
                        }}
                    >
                        <iframe
                            title="googleMap"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11554.605836795135!2d-116.3615408!3d43.6137982!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54aef9c95a36d2eb%3A0x431074a56344f459!2sBlack%20Box%20VR%20Fitness!5e0!3m2!1sen!2sus!4v1697741763244!5m2!1sen!2sus"
                            width="100%"
                            height="100%"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Box>
                </Grid>
                <Grid item xs={12} tl={9}>
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent={{ xs: 'space-between', tl: 'space-around' }}
                        flexWrap={{ xs: 'wrap', tl: 'nowrap' }}
                        minHeight="100%"
                        ml={{ xs: 0, lg: '8%' }}
                        mt={{ xs: 4, tl: 0 }}
                    >
                        <Box mb={{ xs: 2, tl: 0 }}>
                            <Box display="flex" mb={2}>
                                <PlaceOutlinedIcon sx={{ fontSize: 28, display: { xs: 'none', lg: 'block' } }} />
                                <Heading5
                                    sx={{
                                        fontSize: { xs: 14, lg: 16 },
                                        ml: { lg: 1.5 },
                                        mt: { lg: '6px' },
                                        lineHeight: 1.625,
                                    }}
                                >
                                    {street}
                                    <br />
                                    {city}, {state} {zipCode}
                                </Heading5>
                            </Box>
                            <CustomButton
                                sx={{ display: { xs: 'none', lg: 'inline-flex' } }}
                                href="https://maps.google.com/maps?ll=43.613798,-116.361541&z=13&t=m&hl=en&gl=US&mapclient=embed&cid=4832490653852431449"
                            >
                                Get Directions
                            </CustomButton>
                            <Typography
                                sx={{
                                    '& a': {
                                        display: { xs: 'block', lg: 'none' },
                                        color: '#0076FF',
                                        fontWeight: 700,

                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    },
                                }}
                            >
                                <Link to="https://maps.google.com/maps?ll=43.613798,-116.361541&z=13&t=m&hl=en&gl=US&mapclient=embed&cid=4832490653852431449">
                                    Get Directions
                                </Link>
                            </Typography>
                        </Box>
                        <Box mb={{ xs: 2, tl: 0 }}>
                            <Heading4 sx={{ fontSize: { xs: 14, tl: 16, lg: 24 } }}>Contact Us</Heading4>
                            <Typography sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                <PhoneOutlinedIcon
                                    sx={{ fontSize: 28, mr: 2, display: { xs: 'none', lg: 'block' } }}
                                />
                                {phone}
                            </Typography>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center', mt: 2, fontSize: { xs: 14, lg: 16 } }}
                            >
                                <EmailOutlinedIcon
                                    sx={{ fontSize: 28, mr: 2, display: { xs: 'none', lg: 'block' } }}
                                />
                                {email}
                            </Typography>
                        </Box>
                        <Box>
                            <Heading4 sx={{ fontSize: { xs: 14, tl: 16, lg: 24 }, mb: 2 }}>Open Hours</Heading4>
                            {openHours.map((obj) => (
                                <Typography key={obj.day} sx={{ mt: 0.5, fontSize: { xs: 14, lg: 16 } }}>
                                    {obj.name}: {obj.open} - {obj.close}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Addresses;
