import { Box, Grid } from "@mui/material";
import { Heading1, Heading2 } from "../../../../components/Heading";
import Container from "../../../../components/Layout/Container";
import TeamBackground from '../../../../assets/img/boise-leaders/team-bg.png';
import GymLeaderboards from "./GymLeaderboards";

const PreRaidGymLeaders = ({
  teamLeaderboards
}) => {
  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1 sx={{ px: { xs: 2, md: 0 } }}>Gym Leaders</Heading1>
      <Box px={{ xs: 2, md: 0 }}>
        <Box
          sx={{
            width: '100%',
            height: { xs: 57, lg: 90 },
            background: `url(${TeamBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            backgroundPosition: 'left center',
            display: 'flex',
            alignItems: 'center',
            mt: 6,
          }}
        >
          <Heading2 sx={{ ml: 5 }}>Team</Heading2>
        </Box>
        <Grid container columnSpacing={5} rowSpacing={6} mt={{ xs: 3, tl: 4 }}>
          <Grid item xs={12} lg={6}>
            <GymLeaderboards
              title="Boost Tokens Earned"
              data={teamLeaderboards?.topTeamsByBoostTokens}
              type="team"
              valueKey="boostTokens"
            />
          </Grid>
          <Grid item xs={12} lg={6} mt={{ xs: 4, lg: 0 }}>
            <GymLeaderboards
              title="Team Workouts Completed"
              data={teamLeaderboards?.topTeamsByTotalWorkouts}
              type="team"
              valueKey="totalWorkouts"
            />
          </Grid>
          <Grid item xs={12} lg={6} mt={{ xs: 4, lg: 0 }}>
            <GymLeaderboards
              title="Average Workouts per MEMBER"
              data={teamLeaderboards?.topTeamsByAverageWorkoutsPerMember}
              type="team"
              valueKey="averageWorkouts"
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default PreRaidGymLeaders;
