import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Heading4 } from '../../../../components/Heading';
import S3Avatar from '../../../../components/S3Avatar';

import ImmortalsLogo from '../../../../assets/img/team/logo/immortals.png';
import ChallengesIcon from '../../../../assets/img/team/challenges.svg';

const BoiseTeamCard = ({ data }) => {
  const teamName = data?.teamName;
  const teamDescription = data?.teamDescription;
  const teamLogo = data?.teamLogo;
  const memberCount = data?.memberCount || 0;
  const captainAvatar = data?.captainProfilePic;
  const captainName = data?.captainUsername;

  return (
    <Box maxWidth={191} sx={{ cursor: 'pointer' }}>
      <Link to={`/team/${teamName}`}>
        <Box sx={{ '& img': { width: '100%', height: 191, border: '1px solid #313131' } }}>
          <img src={teamLogo ?? ImmortalsLogo} alt={teamName} />
        </Box>
      </Link>
      <Link to={`/team/${teamName}`}>
        <Heading4
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            mt: 2,
          }}
        >
          {teamName}
        </Heading4>
      </Link>
      <Typography
        fontSize={14}
        fontWeight={700}
        mt={1}
        display="flex"
        alignItems="center"
        sx={{
          '& img': { width: 24, height: 24, mr: 1 },
        }}
      >
        <img src={ChallengesIcon} alt="" />
        {memberCount}/12
      </Typography>
      <Typography
        fontSize={14}
        sx={{
          height: 65,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,
          WebkitBoxOrient: 'vertical',
          mt: 1,
        }}
      >
        {teamDescription}
      </Typography>
      <Box display="flex" alignItems="center" mt={2}>
        <Link to={`/member/${captainName}`}>
          <Box sx={{ minWidth: 40, height: 40, borderRadius: '50%' }}>
            <S3Avatar src={captainAvatar} sx={{ borderRadius: '50%' }} />
          </Box>
        </Link>
        <Box ml={2}>
          <Link to={`/member/${captainName}`}>
            <Typography fontSize={14} fontWeight={700} sx={{ wordBreak: 'break-all' }}>
              {captainName}
            </Typography>
          </Link>
          <Typography fontSize={12} mt={1}>
            Captain
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BoiseTeamCard;
