import { Box } from '@mui/material';
import { PostTargetType } from '@amityco/js-sdk';
import { useParams } from 'react-router-dom';

import { useTeamDetails } from '../../../services/communityService';
import { SOCIAL_SEARCH_TYPE } from '../../../config/constants';

import MetaTags from '../../../components/MetaTags';
import { Heading1 } from '../../../components/Heading';
import Container from '../../../components/Layout/Container';
import SocialFeed from '../../../components/SocialFeed';
import Overview from '../../../components/Overview';
import FullScreenBox from '../../../components/Layout/FullScreenBox';
import { Heading2 } from '../../../components/Heading';
import Loading from '../../../components/Loading';

import Details from './Details';
import Members from './Members';
import SeasonInfo from './SeasonInfo';
import TeamProfileFooter from './Footer';

const TeamProfile = () => {
  const { teamname } = useParams();
  const {
    data: { data, isLoading },
    error,
  } = useTeamDetails('teamname', teamname);

  if (error || data?.results?.error) {
    return (
      <FullScreenBox>
        <Heading2>Team Not Found</Heading2>
      </FullScreenBox>
    );
  }

  const teamDetails = data?.results;
  const teamId = teamDetails?.team_id;
  const amityLoginId = teamDetails?.team_captain;

  return (
    <Box>
      <MetaTags title={`Black Box VR - ${teamname} Team Profile`} />
      {isLoading ? (
        <Loading fullscreen />
      ) : (
        <>
          <Details data={teamDetails} />
          <Box mt={{ xs: 8, tl: 7, lg: 6 }}>
            <Overview
              title="Team Totals"
              description="Bound by sweat, driven by strength. Together, the team conquers big weights."
              data={teamDetails}
            />
          </Box>
          <SeasonInfo data={teamDetails} />
          <Members data={teamDetails} />
          <Container>
            <Heading1 sx={{ mt: { xs: 5, tl: 10, lg: 16 }, ml: { xs: 2, tl: 0 } }}>
              Team Feed
            </Heading1>
          </Container>
          {!!amityLoginId && (
            <SocialFeed
              userId={amityLoginId}
              targetType={PostTargetType.CommunityFeed}
              searchType={SOCIAL_SEARCH_TYPE.TEAM}
              showTargetId={teamId}
              sx={{ mt: 3 }}
            />
          )}
          <TeamProfileFooter />
        </>
      )}
    </Box>
  );
};

export default TeamProfile;
