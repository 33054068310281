import { Box } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer/FooterMenu';
import { Heading2 } from '../../components/Heading';
import FullScreenBox from '../../components/Layout/FullScreenBox';

import { useTeamDetails } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';
import Details from '../Profiles/TeamProfile/Details';
import Recap from './Recap';
import Members from './Members';
import SeasonInfo from './SeasonInfo';
import FragmentsFound from './FragmentsFound';

const PostRaidTeamRecap = () => {
  const [teamRecapData, setData] = useState(null);
  const [isTeamRecapLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { teamname, season, month } = useParams();

  const {
    data: { data: teamData, isLoading: isTeamLoading },
    error: teamError,
  } = useTeamDetails('teamname', teamname);

  const teamDetails = teamData?.results;

  useEffect(() => {
    const fetchRecapData = async () => {
      if (!teamname || !season || !month) {
        setError('Missing required parameters');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const seasonNumber = parseInt(season, 10);
        const monthNumber = parseInt(month, 10);

        if (isNaN(seasonNumber) || isNaN(monthNumber)) {
          throw new Error('Invalid season or month number');
        }

        const response = await callBackbonePostApi({
          action: "getTeamRaidRecap",
          teamName: teamname,
          season: seasonNumber,
          month: monthNumber
        }, '');

        if (response?.status === 200 && response?.data) {
          setData(response.data);
          setError(null);
        } else {
          throw new Error('Failed to fetch recap data');
        }
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecapData();
  }, [teamname, season, month]);

  const breadcrumbs = useMemo(() => {
    return [
      { title: 'Home', link: 'https://www.blackbox-vr.com' },
      { title: 'Community', link: '/' },
      { title: 'Teams', link: '/' },
      { title: teamname, link: `/team/${teamname}` },
      { title: 'Recaps', link: `/team/${teamname}` },
      { title: `Season ${season} Month ${month} Post Raid Recap` }
    ]
  }, [teamname, season, month])

  if (teamError || error) {
    return (
      <FullScreenBox>
        <Heading2>
          {teamError ? 'Team not found' : `Error: ${error}`}
        </Heading2>
      </FullScreenBox>
    );
  }

  const isLoadingAny = isTeamLoading || isTeamRecapLoading;

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${teamname}'s Season ${season} Month ${month} Recap`}
        description={`View ${teamname}'s team performance for Season ${season} Month ${month} in Black Box VR`}
      />

      {isLoadingAny ? (
        <Loading fullscreen />
      ) : (
        <>
          <Details
            data={teamDetails}
            breadcrumbs={breadcrumbs}
          />
          <Recap
            data={teamRecapData}
            season={season}
            month={month}
          />
          <Members
            data={teamDetails}
            recapData={teamRecapData}
            season={season}
            month={month}
          />
          <FragmentsFound
            data={teamRecapData}
            season={season}
            month={month}
          />
          <SeasonInfo
            data={teamDetails}
            recapData={teamRecapData}
            teamname={teamname}
            season={season}
            month={month}
          />
        </>
      )}
      <Footer />
    </Box>
  );
};

export default PostRaidTeamRecap;