import { useParams } from "react-router-dom";
import { useBoiseNewsEvents, useGymDetails } from "../../../services/communityService";
import FullScreenBox from "../../../components/Layout/FullScreenBox";
import { Heading2 } from "../../../components/Heading";
import { Box } from "@mui/material";
import MetaTags from "../../../components/MetaTags";
import Loading from "../../../components/Loading";
import TopBanner from "../../Profiles/GymProfile/TopBanner";
import Overview from "../../../components/Overview";
import { useEffect, useMemo, useState } from "react";
import { callBackbonePostApi } from "../../../services/backboneApiService";
import SeasonDetail from "./SeasonDetail";
import PreRaidTipsForSuccess from "./TipsForSuccess";
import PreRaidHighlights from "./Highlights";
import FooterMenu from "../../../components/Footer/FooterMenu";
import PreRaidGymLeaders from "./GymLeaders";
import PreRaidTopPerformers from "./TopPerformers";
import PreRaidArenaRaidBoostItems from "./ArenaRaidBoostItems";
import SeasonInfo from "../../Community/SeasonInfo";
import BoiseNewsEvents from "../../Profiles/GymProfile/BoiseNewsEvents";
import { CustomButton } from "../../../components/Buttons";

const PreRaidGymRecap = () => {
  const { id, season, month } = useParams();
  const {
    data: { data: gymInfodata, isLoading: isGymInfoLoading },
    error: gymInfoError,
  } = useGymDetails(id);
  const {
    data: { data: newsData, isLoading: isNewsLoading },
  } = useBoiseNewsEvents();
  const [showLoading, setShowLoading] = useState(true);
  const [error, setError] = useState();
  const [seasonConfig, setSeasonConfig] = useState();
  const [recapInfo, setRecapInfo] = useState();

  const gymInfo = gymInfodata?.results;

  useEffect(() => {
    const fetchSeasonDetails = async () => {
      if (!season || !id || !month) {
        return;
      }
      setShowLoading(true);
      try {
        const response = await callBackbonePostApi({
          action: "getSeasonDetailsFromConfig",
          season: season
        });
        if (response?.status === 200 && response?.data) {
          setSeasonConfig(response.data);
        } else {
          throw new Error(`Failed to fetch Season Details`);
        }

        const preRaidRes = await callBackbonePostApi({
          action: "getGymPreRaidRecap",
          gymId: id,
          seasonNumber: season,
          monthNumber: month
        });
        if (preRaidRes?.status === 200 && preRaidRes?.data) {
          setRecapInfo(preRaidRes.data);
        } else {
          throw new Error(`Failed to fetch Season Details`);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setShowLoading(false);
      }
    }

    fetchSeasonDetails();
  }, [id, season, month]);

  const breadcrumbs = useMemo(() => {
    return [
      { title: 'Home', link: 'https://www.blackbox-vr.com' },
      { title: 'Community', link: '/' },
      { title: gymInfo?.GymName, link: `/gym/${id}/${gymInfo?.GymName}` },
      { title: 'Recaps' },
      { title: `Season ${season} Month ${month} Pre Raid Recap` }
    ]
  }, [id, gymInfo, season, month])

  if (gymInfoError || (!isGymInfoLoading && !gymInfo)) {
    return (
      <FullScreenBox>
        <Heading2>Gym not found</Heading2>
      </FullScreenBox>
    );
  }

  if (error) {
    return (
      <FullScreenBox>
        <Heading2>{error}</Heading2>
      </FullScreenBox>
    );
  }

  const isLoadingAny = isGymInfoLoading || showLoading;

  return (
    <Box>
      <MetaTags title={`${gymInfo?.GymName} Gym's Season ${season} Month ${month} Pre-raid Recap`} />
      {isLoadingAny ? (
        <Loading fullscreen />
      ) : (
        <>
          <TopBanner name={gymInfo?.GymName} bannerImg={gymInfo?.gymImage} breadcrumbs={breadcrumbs} />
          <SeasonDetail config={seasonConfig} season={season} month={month} />
          <Box mt={{ xs: 4, tl: 6, lg: 6 }}>
            <Overview
              title="Pre-raid Gym Totals"
              description="Bound by Progress, Driven by Dedication. Together, Our Numbers Tell the Story of Triumph."
              data={{
                total_workouts: recapInfo?.gymTotals?.workoutsCompleted ?? 0,
                team_total_sets: recapInfo?.gymTotals?.setCount ?? 0,
                team_total_reps: recapInfo?.gymTotals?.repCount ?? 0,
                team_total_volume: recapInfo?.gymTotals?.totalVolume ?? 0,
                team_total_work: recapInfo?.gymTotals?.totalWork ?? 0,
                team_total_calories_burned: recapInfo?.gymTotals?.totalCaloriesBurned ?? 0,
              }}
            />
          </Box>
          <PreRaidTipsForSuccess />
          <PreRaidHighlights
            mostUsedExercise={recapInfo?.highlights?.mostUsedExercise || {}}
            leastUsedExercise={recapInfo?.highlights?.leastUsedExercise || {}}
            mostUsedChampion={recapInfo?.highlights?.mostUsedChampion || {}}
            mostUsedPowerup={recapInfo?.highlights?.mostUsedPowerup || {}}
          />
          <PreRaidGymLeaders
            teamLeaderboards={recapInfo?.teamLeaderboards || {}}
          />
          <PreRaidTopPerformers
            teamQuests={recapInfo?.teamQuestTopPerformers || {}}
          />
          <PreRaidArenaRaidBoostItems
            boostInfo={recapInfo?.currentBoostItems || {}}
          />
          <SeasonInfo />
          {newsData && (
            <BoiseNewsEvents name={gymInfo?.GymName} data={newsData} isLoading={isNewsLoading} />
          )}
          <Box my={15} sx={{
            textAlign: "center"
          }}>
            <CustomButton href={`/gym/${id}/${gymInfo?.GymName}`} sx={{ marginLeft: "auto", marginRight: "auto" }}>
              View Gym Main Page
            </CustomButton>
          </Box>
          <FooterMenu />
        </>
      )}
    </Box>
  )
}

export default PreRaidGymRecap;
