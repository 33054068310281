import { Box, Stack, Typography } from "@mui/material";
import { Heading3, Heading4, Heading5 } from "../../../../components/Heading";
import { formatCompactNumber } from "../../../../utils";
import S3Avatar from "../../../../components/S3Avatar";
import { Link } from "react-router-dom";

const PreRaidRecapPerformerItem = ({
                                       user,
                                       rank,
                                   }) => {
    const gradientBackgrounds = [
        "linear-gradient(to bottom, #925F01 25%, #C3A128 50%, #FEF89D 75%, #F4CE02 100%)",
        "linear-gradient(to bottom, #777777 25%, #C5C5C5 50%, #F0F0F0 75%, #767676 100%)",
        "linear-gradient(to bottom, #5B3619 20%, #90633A 40%, #F3D1A8 60%, #AD763D 80%, #7D4313 100%)"
    ];

    return (
        <Box display="flex" gap={1}>
            <Stack
                width={46}
                height={46}
                borderRadius="50%"
                p="2px"
                sx={{
                    background: `${gradientBackgrounds[rank - 1]}`
                }}
            >
                <Box
                    width={46}
                    height={46}
                    borderRadius="50%"
                    bgcolor="#141516"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Heading4>#{rank}</Heading4>
                </Box>
            </Stack>
            <Stack
                width={80}
                height={80}
                p="1px"
                sx={{
                    background: `${gradientBackgrounds[rank - 1]}`
                }}
            >
                <Link to={`/member/${user?.userName}`}>
                    <S3Avatar src={user?.profilePicUrl} width="80px" height="80px" />
                </Link>
            </Stack>
            <Stack>
                <Link to={`/member/${user?.userName}`}>
                    <Heading5>{user?.userName}</Heading5>
                </Link>
                <Typography mt={0.5} fontSize={14} lineHeight="22px">{user?.teamName}</Typography>
                <Heading3 mt={1}>{formatCompactNumber(user?.count)}</Heading3>
            </Stack>
        </Box>
    );
}

export default PreRaidRecapPerformerItem;