import { Grid } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading1 } from '../../../components/Heading';
import FeaturedCard from '../../Profiles/GymProfile/FeaturedMembers/FeaturedCard';
import Loading from '../../../components/Loading';

import { useGymDetails } from '../../../services/communityService';

const FeaturedMember = () => {
  const {
    data: { data, isLoading },
  } = useGymDetails(2);
  const featuredMembers = data?.results?.FeaturedMembers || {};

  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1 sx={{ marginBottom: 6 }}>Featured Members</Heading1>
      {!!isLoading && <Loading />}
      {!isLoading && <Grid container columnSpacing={5}>
        {Object.keys(featuredMembers).map((username, index) => (
          <Grid key={index} item xs={12} md={6} mb={{ xs: 4, tl: 0 }}>
            <FeaturedCard
              index={index}
              isCommunity
              data={{
                username,
                fullName: featuredMembers[username]?.fullName,
                testimonial: `"${featuredMembers[username]?.testimonial}"`,
                image: featuredMembers[username]?.profilePicUrl,
              }}
            />
          </Grid>
        ))}
      </Grid>}
    </Container>
  );
};

export default FeaturedMember;
