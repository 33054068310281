import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { useScrollToTop } from './hooks/useScrollToTop';

import PortalLayout from './containers/PortalLayout';
import PageNotFound from './containers/PageNotFound';
import SignUp from './containers/Auth/SignUp'; // Old
import Registration from './containers/Auth/Registration';
import SignIn from './containers/Auth/SignIn';
import ForgotPassword from './containers/Auth/ForgotPassword';
import ResetPassword from './containers/Auth/ResetPassword';
import GymMasterScheduling from './containers/GymMaster/Scheduling';
import MemberProfile from './containers/Profiles/MemberProfile';
import TeamProfile from './containers/Profiles/TeamProfile';
import GymProfile from './containers/Profiles/GymProfile';
import MemberInterview from './containers/MemberInterview';
import MemberQuestionnaire from './containers/MemberQuestionnaire';
import MainLeaderboards from './containers/MainLeaderboards';
import Leaderboards from './containers/Leaderboards';
import UnityGems from './containers/UnityGems';
import Community from './containers/Community';
import Seasons from './containers/Seasons';
import SeasonRecap from './containers/SeasonRecap';
import WeekRecap from './containers/WeekRecap';
import ChatPage from './containers/Chat';
import VideoList from './containers/VideoList';
import Workout from './containers/Workout';
import TeamRecap from './containers/TeamRecap';
import PersonalRecords from './containers/PersonalRecords';
import GymRecap from './containers/GymRecap';

function App() {
  useScrollToTop();
  const location = useLocation();

  const [currentPath, setCurrentPath] = useState(null);
  const [previousPath, setPreviousPath] = useState(null);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    }
  }, [currentPath, location.pathname]);

  return (
    <>
      <Routes>
        <Route exact path="registration" element={<SignUp />} />
        <Route exact path="registration/v2" element={<SignUp isScheduling />} />

        <Route element={<PortalLayout />}>
          <Route path="/" element={<Community />} />
          <Route exact path="signup/:gymId" element={<Registration />} />
          <Route exact path="signup" element={<Registration />} />
          <Route exact path="signin" element={<SignIn previousPath={previousPath} />} />
          <Route exact path="forgotpassword" element={<ForgotPassword />} />
          <Route exact path="resetpassword/:recoveryToken" element={<ResetPassword />} />
          <Route exact path="member/:username" element={<MemberProfile />} />
          <Route exact path="team/:teamname" element={<TeamProfile />} />
          <Route exact path="team/:teamname/recaps/raid/season/:season/month/:month" element={<TeamRecap type="post-raid" />} />
          <Route exact path="team/:teamname/recaps/preraid/season/:season/month/:month" element={<TeamRecap type="pre-raid" />} />
          <Route exact path="gym/:id/:gymName" element={<GymProfile />} />
          <Route exact path="gymmaster/scheduling/:userId" element={<GymMasterScheduling />} />
          <Route exact path="gym/:id/:gymName/recaps/preraid/season/:season/month/:month" element={<GymRecap type="pre-raid" />} />
          <Route exact path="main-leaderboards" element={<MainLeaderboards />} />
          <Route exact path="leaderboards" element={<Leaderboards />} />
          <Route exact path="unity-gems" element={<UnityGems />} />
          <Route exact path="seasons" element={<Seasons />} />
          <Route exact path="chat/:userId" element={<ChatPage />} />
          <Route exact path="member/:username/recaps/season/:season" element={<SeasonRecap />} />
          <Route exact path="member/:username/recaps/week/:date" element={<WeekRecap />} />
          <Route exact path="member/:username/workouts" element={<VideoList />} />
          <Route exact path="member/:username/workouts/:sessionId" element={<Workout />} />
          <Route exact path="member/:username/records" element={<PersonalRecords />} />
          <Route exact path="member/:username/vip/:vipCode" element={<MemberProfile />} />
          <Route exact path="404" element={<PageNotFound />} />
        </Route>

        <Route element={<PortalLayout isRequiredAuth />}>
          <Route exact path="interview" element={<MemberInterview />} />
        </Route>

        <Route element={<PortalLayout isHideHeader />}>
          <Route exact path="member/:username/answers" element={<MemberQuestionnaire />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
