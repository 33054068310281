import PostRaidTeamRecap from "./PostRaidRecap";
import PreRaidTeamRecap from "./PreRaidRecap";

const TeamRecap = ({ type }) => {
  if (type === 'post-raid') {
    return <PostRaidTeamRecap />;
  } else if (type === 'pre-raid') {
    return <PreRaidTeamRecap />;
  }
};

export default TeamRecap;