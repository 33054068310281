import { Box, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer/FooterMenu';
import { Heading2 } from '../../components/Heading';
import FullScreenBox from '../../components/Layout/FullScreenBox';

import { callBackbonePostApi } from '../../services/backboneApiService';
import PreRaidTeamRecapOverview from './Overview';
import PreRaidTeamRecapHighlights from './Highlights';
import PreRaidTeamRecapArenaRaidBoostItems from './ArenaRaidBoostItems';
import PreRaidTeamRecapTopPerformers from './TopPerformers';
import MemberPerformance from './MemberPerformance';
import { CustomButton } from '../../components/Buttons';
import Details from './Details';

const PreRaidTeamRecap = () => {
  const [teamInfo, setTeamInfo] = useState(null);
  const [teamMembers, setTeamMembers] = useState(null);
  const [teamStats, setTeamStats] = useState(null);
  const [gymInfo, setGymInfo] = useState(null);
  const [teamRecapData, setData] = useState(null);
  const [seasonConfig, setSeasonConfig] = useState(null);

  const [isTeamRecapLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [teamError, setTeamError] = useState(null);
  const [error, setError] = useState(null);

  const { teamname, season, month } = useParams();

  const fetchDataFromBackbone = async (body, showLoading = true) => {
    try {
      showLoading && setIsLoading(true);
      const response = await callBackbonePostApi(body);
      if (response?.status === 200 && response?.data) {
        return response.data;
      } else {
        throw new Error(`Failed to fetch ${body?.action}`);
      }
    } catch (err) {
      setError(err.message);
      if (body?.action === "getTeamInfoByTeamName") {
        setTeamError("Team Not Found");
      }
    } finally {
      (showLoading) && (error || teamRecapData) && setIsLoading(false);
    }
  }

  useEffect(() => {
    const fetchTeamInfo = async ({ teamName }) => {
      if (!teamName) {
        return;
      }
      const teamInfoRes = await fetchDataFromBackbone({
        action: "getTeamInfoByTeamName",
        teamName
      });
      if (teamInfoRes) {
        setTeamInfo(teamInfoRes);
      }
    }

    fetchTeamInfo({ teamName: teamname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamname])

  useEffect(() => {
    const fetchSeasonConfig = async () => {
      if (!teamname || !season || !month) {
        return;
      }
      const seasonConfigRes = await fetchDataFromBackbone({
        action: "getSeasonDetailsFromConfig",
        season: season
      });
      if (seasonConfigRes) {
        setSeasonConfig(seasonConfigRes);
      }
    }

    fetchSeasonConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamname, season, month]);

  useEffect(() => {
    const fetchTeamStatsAndMembers = async () => {
      if (!teamInfo?.teamId || !teamInfo?.members) {
        return;
      }
      const teamStatsRes = await fetchDataFromBackbone({
        action: "getTeamStats",
        teamId: teamInfo?.teamId
      }, false);
      const teamMembersRes = await fetchDataFromBackbone({
        action: "getBasicUserProfileInfoInBatch",
        userIds: teamInfo?.members
      }, false);
      const gymInfoRes = await fetchDataFromBackbone({
        action: "getGymLocationInfoByGymId",
        gymId: teamInfo?.gymId
      }, false);
      if (teamStatsRes) {
        setTeamStats(teamStatsRes);
      }
      if (teamMembersRes) {
        setTeamMembers(teamMembersRes);
      }
      if (gymInfoRes) {
        setGymInfo(gymInfoRes);
      }
      setShowSkeleton(false);
    }

    fetchTeamStatsAndMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamInfo?.teamId])

  useEffect(() => {
    const fetchRecapData = async () => {
      if (!season || !month) {
        return;
      }

      try {
        setIsLoading(true);
        const seasonNumber = parseInt(season, 10);
        const monthNumber = parseInt(month, 10);

        if (isNaN(seasonNumber) || isNaN(monthNumber)) {
          throw new Error('Invalid season or month number');
        }

        const response = await callBackbonePostApi({
          action: "getTeamPreRaidRecap",
          teamId: teamInfo?.teamId,
          seasonNumber: seasonNumber,
          monthNumber: monthNumber
        }, '');

        if (response?.status === 200 && response?.data) {
          setData(response.data);
          setError(null);
        } else {
          throw new Error('Data not found');
        }
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (teamInfo?.teamId) {
      fetchRecapData();
    }
  }, [teamInfo, season, month])

  const breadcrumbs = useMemo(() => {
    return [
      { title: 'Home', link: 'https://www.blackbox-vr.com' },
      { title: 'Community', link: '/' },
      { title: gymInfo?.name, link: `/gym/${teamInfo?.gymId}/${gymInfo?.name}` },
      { title: 'Teams', link: '/' },
      { title: teamname, link: `/team/${teamname}` },
      { title: 'Recaps', link: `/team/${teamname}` },
      { title: `Season ${season} Month ${month} Pre Raid Recap` }
    ]
  }, [teamname, gymInfo, season, month, teamInfo?.gymId])

  if (teamError || error) {
    return (
      <FullScreenBox>
        <Heading2>
          {teamError ? 'Team not found' : `Error: ${error}`}
        </Heading2>
      </FullScreenBox>
    );
  }

  const isLoadingAny = isTeamRecapLoading;

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${teamname}'s Season ${season} Month ${month} Pre-raid Recap`}
        description={`View ${teamname}'s team performance for Season ${season} Month ${month} in Black Box VR`}
      />

      {isLoadingAny ? (
        <Loading fullscreen />
      ) : (
        <>
          <Details
            isLoading={showSkeleton}
            teamInfo={teamInfo}
            teamStats={teamStats}
            breadcrumbs={breadcrumbs}
          />
          <PreRaidTeamRecapOverview
            config={seasonConfig}
            boostInfo={teamRecapData?.teamBoostInfo || []}
            boostTokenInfo={teamRecapData?.teamBoostTokenInfo || {}}
            season={season}
            month={month}
          />
          <PreRaidTeamRecapHighlights
            mostUsedExercise={teamRecapData?.mostUsedExercise || {}}
            leastUsedExercise={teamRecapData?.leastUsedExercise || {}}
            mostUsedChampion={teamRecapData?.mostUsedChampion || {}}
            mostUsedPowerup={teamRecapData?.mostUsedPowerup || {}}
          />
          <PreRaidTeamRecapArenaRaidBoostItems
            boostInfo={teamRecapData?.teamBoostInfo || []}
            boostTokenInfo={teamRecapData?.teamBoostTokenInfo || {}}
          />
          <PreRaidTeamRecapTopPerformers
            teamQuests={teamRecapData?.teamQuestsWithProgress || []}
            members={teamMembers || []}
          />
          <MemberPerformance
            isLoading={showSkeleton}
            teamInfo={teamInfo}
            members={teamMembers}
            sessionStates={teamRecapData?.sessionStatsByPlayer || {}}
          />
          <Box sx={{
            maxWidth: "600px",
            textAlign: "center",
            margin: "160px auto"
          }}>
            <Typography fontSize={{ xs: 14, md: 18, lg: 24 }} lineHeight="32px">
              How did your team do compared to the other teams at your gym location?
            </Typography>
            <CustomButton href={`/gym/${teamInfo?.gymId}/${gymInfo?.name}/recaps/preraid/season/${season}/month/${month}`} sx={{ marginTop: '28px', height: '88px', fontSize: '32px' }}>
              View Gym Leaderboards
            </CustomButton>
          </Box>
          <Box my={15} sx={{
            textAlign: "center"
          }}>
            <CustomButton href={`/team/${teamname}`} sx={{ marginLeft: "auto", marginRight: "auto" }}>
              View Team Main Page
            </CustomButton>
          </Box>
        </>
      )}
      <Footer />
    </Box>
  );
};

export default PreRaidTeamRecap;