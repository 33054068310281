import { Box } from '@mui/material';

import BackImage from './BackImage';
import Container from '../../../../components/Layout/Container';
import LocalImage from '../../../../components/LocalImage';
import { Heading1, Heading4 } from '../../../../components/Heading';
import CustomBreadcrumbs from '../../../../components/Breadcrumbs';

const TopBanner = ({ name, bannerImg, breadcrumbs }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: { xs: 456, md: 330 },
        display: 'flex',
        alignItems: 'center',
        background: '#141516'
      }}
    >
      {bannerImg && <BackImage image={bannerImg} />}
      <Container>
        {breadcrumbs && (
          <Box pb={3}>
            <CustomBreadcrumbs items={breadcrumbs} />
          </Box>
        )}
        <Box
          sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', tl: 'row' } }}
        >
          <Box sx={{ width: { xs: 200, lg: 191 }, height: { xs: 200, lg: 191 } }}>
            <LocalImage
              src={`img/logo-mark.png`}
              width="100%"
              height="100%"
              alt=""
              loading="lazy"
            />
          </Box>
          <Box
            width={{ xs: 'calc(100% - 16px)', tl: 'auto' }}
            ml={{ xs: 2, tl: 3, lg: 6 }}
            mt={{ xs: 4, tl: 0 }}
          >
            <Heading4>GyM COMMUNITY</Heading4>
            <Heading1 sx={{ mt: 2 }}>{name}</Heading1>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TopBanner;
