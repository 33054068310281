import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { PostTargetType } from '@amityco/js-sdk';
import { Link } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
import SocialFeed from '../../components/SocialFeed';
import Overview from '../../components/Overview';
import Loading from '../../components/Loading';
import TopBanner from '../Leaderboards/TopBanner';
import BoiseNewsEvents from '../Profiles/GymProfile/BoiseNewsEvents';
import RightCornerTag from '../Profiles/MemberProfile/Details/RightCornerTag';
import Banner from './Banner';
import FeaturedMember from './FeaturedMember';
import SeasonInfo from './SeasonInfo';
import GymProfileFooter from '../Profiles/GymProfile/Footer';
import BoiseTeams from "../Profiles/GymProfile/BoiseTeams";

import { SOCIAL_SEARCH_TYPE } from '../../config/constants';
import CommunitydBg from '../../assets/img/community/bg.webp';
import { useBoiseNewsEvents } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';
import {Heading3, Heading5} from "../../components/Heading";

const Community = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [featuredTeams, setFeaturedTeams] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const communityPromise = callBackbonePostApi({ action: "getCommunityAggregatedData" }, '');
                const teamsPromise = callBackbonePostApi({ action: "getCommunityFeaturedTeams" }, '');

                const timeoutPromise = new Promise((_, reject) =>
                    setTimeout(() => reject(new Error("API request timed out")), 10000)
                );

                const [communityData, teamsData] = await Promise.race([
                    Promise.all([communityPromise, teamsPromise]),
                    timeoutPromise
                ]);

                if (communityData?.status === 200 && communityData?.data) {
                    setData(communityData.data);
                }

                if (teamsData?.status === 200 && teamsData?.data?.featuredTeams) {
                    setFeaturedTeams(teamsData.data.featuredTeams);
                }

                setIsLoading(false);
            } catch (error) {
                console.error("[Community] Fetch error:", error.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const {
        data: { data: newsData, isLoading: isNewsLoading },
    } = useBoiseNewsEvents();

    const breadcrumbs = [
        { title: 'Home', link: 'https://www.blackbox-vr.com' },
        { title: 'Community' }
    ];

    return (
        <Box>
            <MetaTags
                title={`Black Box VR - The VR Athlete Community`}
                description={`Black Box VR - The VR Athlete Community`}
            />
            <RightCornerTag />
            <TopBanner
                title={"YOUR NEXT-GEN FIT SQUAD"}
                subTitle={"THE VR ATHLETE COMMUNITY"}
                breadcrumbs={breadcrumbs}
            />
            <Box
                sx={{
                    height: { xs: 300, tl: 592 },
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: `url(${CommunitydBg})`,
                }}
            />
            <Banner />
            {isLoading && <Loading />}
            {!isLoading && data && (
                <Overview
                    title="Community Totals"
                    description=""
                    data={{
                        total_workouts: data.total_workoutcount ?? 0,
                        team_total_sets: data.total_setcount ?? 0,
                        team_total_reps: data.total_repcount ?? 0,
                        team_total_volume: data.total_volume ?? 0,
                        team_total_work: data.total_work ?? 0,
                        team_total_calories_burned: data.total_caloriesburned ?? 0,
                    }}
                    smallHeading
                />
            )}
            <FeaturedMember />
            <SeasonInfo />
            {featuredTeams && !isLoading && (
                <BoiseTeams
                    name="Featured Teams"
                    teams={featuredTeams}
                />
            )}
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Link to="/main-leaderboards">
                    <Heading3 sx={{ color: "#0076FF" }}>VIEW MEMBER AND TEAM LEADERBOARDS</Heading3>
                </Link>
            </Box>
            <BoiseNewsEvents
                name="News And Events"
                data={newsData}
                viewAllSx={{ background: "transparent", borderColor: "#0076FF" }}
                isLoading={isNewsLoading}
            />
            <SocialFeed
                userId={'5cb20e57c7a3f404ff343783'}
                targetType={PostTargetType.CommunityFeed}
                searchType={SOCIAL_SEARCH_TYPE.GYM}
                sx={{ mt: { xs: 8, tl: 10, lg: 16 } }}
            />
            <GymProfileFooter />
        </Box>
    );
};

export default Community;