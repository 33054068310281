import { Box, Skeleton, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading1 } from '../../../components/Heading';

import ChallengesIcon from '../../../assets/img/team/challenges.svg';
import PlaceholderIcon from '../../../assets/img/placeholder-img.webp';
import MembersTable from './MembersTable';
import MobileMembersTable from './MobileMembersTable';
import { sortObjectArray } from '../../../utils';

const MemberPerformance = ({
  isLoading,
  teamInfo,
  members,
  sessionStates,
}) => {
  const teamTotal = {
    id: teamInfo?.teamId,
    title: { name: 'Team Totals', desc: '', teamName: teamInfo?.teamName },
    image: { name: teamInfo?.teamName, url: teamInfo?.teamLogoUrl },
    workouts: 0,
    work: 0,
    sets: 0,
    calories: 0,
    champions: 0
  };
  const tMembers = [];
  (members || []).forEach((m) => {
    const member = {
      id: m.userId,
      title: { name: m.userName, desc: m.fullName },
      image: { name: m.userName, url: (m.profilePicUrl === 'N/A' || !m.profilePicUrl) ? PlaceholderIcon : m.profilePicUrl },
      workouts: sessionStates?.[m.userId]?.totalWorkouts,
      work: sessionStates?.[m.userId]?.totalWork,
      sets: sessionStates?.[m.userId]?.totalSets,
      calories: sessionStates?.[m.userId]?.totalCaloriesBurned,
      champions: sessionStates?.[m.userId]?.totalChampionsDeployed,
      isCap: teamInfo?.teamOwner === m.userId
    };
    teamTotal['workouts'] += sessionStates?.[m.userId]?.totalWorkouts || 0;
    teamTotal['work'] += sessionStates?.[m.userId]?.totalWork || 0;
    teamTotal['sets'] += sessionStates?.[m.userId]?.totalSets || 0;
    teamTotal['calories'] += sessionStates?.[m.userId]?.totalCaloriesBurned || 0;
    teamTotal['champions'] += sessionStates?.[m.userId]?.totalChampionsDeployed || 0;

    tMembers.push(member);
  });
  const sortedMembers = [teamTotal].concat(sortObjectArray(tMembers, 'workouts', 'desc'));

  return (
    <Box mt={{ xs: 5, tl: 10, lg: 16 }}>
      <Container sx={{ display: 'flex', alignItems: 'center' }}>
        <Heading1 sx={{ ml: { xs: 2, tl: '0' } }}>Member Performance</Heading1>
        <Typography
          fontSize={{ xs: 16, lg: 24 }}
          fontWeight={700}
          ml={{ xs: 3, lg: 4 }}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'center', tl: 'unset' }}
          sx={{ '& img': { mr: 1 } }}
        >
          <img src={ChallengesIcon} alt="Immortals" />
          {sortedMembers.length - 1}/12
        </Typography>
      </Container>
      {isLoading ? (
        <Container>
          <Skeleton variant="rounded" height={600} />
        </Container>
      ) : (<>
        <Box mt={3} display={{ xs: 'none', tl: 'block' }}>
          <MembersTable members={sortedMembers.slice(0, 13)} />
        </Box>
        <Box mt={6} display={{ xs: 'block', tl: 'none' }}>
          <MobileMembersTable members={sortedMembers.slice(0, 13)} />
        </Box>
      </>)}
    </Box>
  );
};

export default MemberPerformance;
