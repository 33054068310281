import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

import Container from '../../../components/Layout/Container';
import Loading from '../../../components/Loading';
import { Heading1 } from '../../../components/Heading';
import { CustomButton } from '../../../components/Buttons';

import { callBackbonePostApi } from '../../../services/backboneApiService';

const formatDate = (date) => {
  if (!date) {
    return "";
  }
  const parts = date.split(",");
  const subParts = parts[0].split(" ");
  return subParts[0].substring(0, 3) + " " + subParts[1];
};

const SeasonInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    callBackbonePostApi({
      action: "getSeasonDetails",
    }, '')
      .then((data) => {
        setIsLoading(false);

        if (data?.status === 200 && data?.data) {
          setData(data?.data);
        }
      })
      .catch(() => {
        setIsLoading(true);
      });
  }, []);

  return (
    <Container sx={{ marginTop: 6 }}>
      {!!isLoading && <Loading />}
      {!isLoading && data && <Box>
        <Heading1 sx={{ px: { xs: 2, md: 0 } }}>Season {data.currentSeasonNumber}: {formatDate(data.month1Start)} - {formatDate(data.seasonEnd)}</Heading1>
        <img
          src={data?.bossBannerLink}
          alt="Season Info"
          width="100%"
          style={{
            marginTop: "40px",
          }}
        />
        <Box sx={{
          marginTop: 5,
          display: "flex",
          flexDirection: {
            xs: "column",
            tl: "column",
            lg: "row"
          },
          alignItems: "center",
        }}>
          <Typography sx={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 400,
            px: { xs: 2, md: 0 }
          }}>
            Black Box VR’s workouts never get dull! Seasons help turn intense exercise into a constantly evolving, fun, and challenging experience that keeps you engaged and motivated on your journey to life-changing health and fitness.
          </Typography>
          <Box sx={{
            width: {
              tl: "100%",
              lg: "700px",
            },
            textAlign: "center",
            padding: {
              xs: "20px 0px 0px 0px",
              tl: "20px 0px 0px 0px",
              lg: "0 115px 0 80px"
            }
          }}>
            <CustomButton href="/seasons">
              View Season Details
            </CustomButton>
          </Box>
        </Box>
      </Box>}
    </Container>
  );
};

export default SeasonInfo;
