import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatCompactNumber, sortObjectArray } from '../../../utils';
import { Heading1, Heading2, Heading3, Heading4 } from '../../../components/Heading';
import S3Avatar from '../../../components/S3Avatar';
import NumberMark from '../../../components/NumberMark';

const MembersList = ({ type, data, isTv }) => {
    let sortedData = [];
    if (type !== 'team') {
        const seenCoopPairs = new Set();

        const formatedData = data.map((item) => {
            if (item.additionalData?.partnerUserId) {
                const pairKey = [item.id, item.additionalData.partnerUserId].sort().join('-');
                if (seenCoopPairs.has(pairKey)) {
                    return null;
                }
                seenCoopPairs.add(pairKey);
            }
            return {
                username: item.username,
                fullName: (item.full_name === 'N/A' ? '' : item.full_name) || (item.fullName === 'N/A' ? '' : item.fullName),
                value: parseInt(item.raidPointsContributed || item.work || item.workoutCount || item.gems || item.raidPoints || item.averageWork || item.streak || item.lifetimeXp || item.seasonxp || item.progressValue),
                image: item.profile_pic_url || item.profilePicUrl || item.teamIcon,
                description: item.teamDescription,
                captain: item.captain,
                hasCoOp: Boolean(item.additionalData?.partnerDisplayName),
                partnerDisplayName: item.additionalData?.partnerDisplayName || '',
                partnerUserId: item.additionalData?.partnerUserId || '',
                partnerFullName: item.additionalData?.partnerFullName,
                id: item.id
            };
        }).filter(item => item && item.value > 0);
        sortedData = sortObjectArray(formatedData, 'value', 'desc');
    } else {
        sortedData = data.map(item => ({
            username: item.teamName,
            fullName: '',
            value: parseInt(item.raidPoints),
            image: item.teamIcon,
            description: item.teamDescription,
            captain: item.captain
        })).filter(item => item.value > 0);
    }

    const renderMemberInfo = (member, index, isPartner = false) => {
        const isTopRanked = index === 0;
        return (
            <Box display="flex" alignItems="center">
                <Box
                    position="relative"
                    sx={{
                        width: isTopRanked ? { xs: 120, tl: 248 } : { xs: 80, tl: 100 },
                        height: isTopRanked ? { xs: 120, tl: 248 } : { xs: 80, tl: 100 },
                        mr: 4
                    }}
                >
                    {!isPartner && <NumberMark number={index + 1} />}
                    <Link to={`/${type}/${isPartner ? member.partnerDisplayName : member.username}`}>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                padding: '1px',
                                background: isTopRanked
                                    ? 'linear-gradient(180deg, #925F01 0%, #C3A128 30%, #FEF89D 40%, #F4CE02 80%)'
                                    : '#313131',
                            }}
                        >
                            <S3Avatar
                                src={isPartner
                                    ? `https://bbvr-user-profile-pics.s3.amazonaws.com/${member.partnerUserId}.png`
                                    : (member.image || "").indexOf("https") === 0 ? member.image : ""
                                }
                            />
                        </Box>
                    </Link>
                </Box>
                <Box>
                    <Link to={`/${type}/${isPartner ? member.partnerDisplayName : member.username}`}>
                        {isTopRanked ? (
                            <Heading1 sx={{ wordBreak: 'break-all' }}>
                                {isPartner ? member.partnerDisplayName : member.username}
                            </Heading1>
                        ) : (
                            <Heading3 sx={{ wordBreak: 'break-all' }}>
                                {isPartner ? member.partnerDisplayName : member.username}
                            </Heading3>
                        )}
                    </Link>
                    {isTopRanked ? (
                        <Typography mt={1} fontSize="1.25rem">
                            {isPartner ? member.partnerFullName : member.fullName}
                        </Typography>
                    ) : (
                        <Typography mt={1}>
                            {isPartner ? member.partnerFullName : member.fullName}
                        </Typography>
                    )}
                </Box>
            </Box>
        );
    };
    
    const renderTopRankedCard = (obj) => (
        <Box sx={{ mt: 3, pl: { xs: 2, lg: 0 } }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mr: { xs: 2, tl: 9 },
                    gap: 4,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}>
                    {/* Main member */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {renderMemberInfo(obj, 0)}
                    </Box>

                    {/* Co-op partner */}
                    {obj.hasCoOp && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4,
                            pl: { xs: 0, md: 8 }
                        }}>
                            <Typography
                                sx={{
                                    color: '#B1B1B1',
                                    fontSize: '1.25rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                CO-OP WITH
                            </Typography>
                            {renderMemberInfo(obj, 0, true)}
                        </Box>
                    )}
                </Box>

                {/* Score - positioned to the right */}
                <Box sx={{
                    textAlign: 'right',
                    minWidth: '200px',
                    mt: 2
                }}>
                    <Heading1>{formatCompactNumber(obj.value)}</Heading1>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box sx={{ maxWidth: 966, margin: 'auto' }}>
            <Box my={8} mx={2}>
                {sortedData.slice(0, isTv ? 10 : sortedData.length).map((obj, index) => (
                    <React.Fragment key={index}>
                        {index === 0 ? (
                            renderTopRankedCard(obj)
                        ) : index < 10 ? (
                            <Box sx={{ mt: 3, pl: { xs: 2, lg: 0 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mr: { xs: 2, tl: 9 },
                                        gap: 4,
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1,
                                        gap: 4,
                                        flexWrap: { xs: 'wrap', md: 'nowrap' }
                                    }}>
                                        {renderMemberInfo(obj, index)}
                                        {obj.hasCoOp && (
                                            <>
                                                <Typography
                                                    sx={{
                                                        color: '#B1B1B1',
                                                        fontSize: '1rem',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    CO-OP WITH
                                                </Typography>
                                                {renderMemberInfo(obj, index, true)}
                                            </>
                                        )}
                                    </Box>
                                    <Box sx={{ whiteSpace: 'nowrap' }}>
                                        <Heading2>{formatCompactNumber(obj.value)}</Heading2>
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{ mt: 3, pl: { xs: 2, lg: 0 } }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mr: { xs: 2, tl: 9 },
                                        gap: 4
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                        <Box position="relative" width={{ xs: 80, tl: 100 }}>
                                            <NumberMark number={index + 1} />
                                        </Box>
                                        <Box>
                                            <Link to={`/${type}/${obj.username}`}>
                                                <Heading4 sx={{ wordBreak: 'break-all', fontWeight: 400 }}>
                                                    {obj.username}
                                                </Heading4>
                                            </Link>
                                        </Box>
                                        {obj.hasCoOp && (
                                            <>
                                                <Typography
                                                    sx={{
                                                        color: '#B1B1B1',
                                                        fontSize: '0.875rem',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    CO-OP WITH
                                                </Typography>
                                                <Link to={`/${type}/${obj.partnerDisplayName}`}>
                                                    <Heading4 sx={{ wordBreak: 'break-all', fontWeight: 400 }}>
                                                        {obj.partnerDisplayName}
                                                    </Heading4>
                                                </Link>
                                            </>
                                        )}
                                    </Box>
                                    <Heading4 sx={{ fontWeight: 400, whiteSpace: 'nowrap' }}>
                                        {formatCompactNumber(obj.value)}
                                    </Heading4>
                                </Box>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="flex-end">
                            <Divider
                                sx={{
                                    borderColor: '#B1B1B1',
                                    mt: 2,
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};

export default MembersList;