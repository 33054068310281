import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';


import Container from '../../../components/Layout/Container';
import LocalImage from '../../../components/LocalImage';
import { CustomButton } from '../../../components/Buttons';
import {callBackbonePostApi} from "../../../services/backboneApiService";

const SignUp = ({ isScheduling }) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [gym, setGym] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onValidate = (fieldName, str, isNotEmoji, isNotSign, isNotWhiteSpace, isNotNumber) => {
    const emojiRegex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const specialRegex = /[@]/;
    const numberRegex = /\d/;
    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.+-]+\.[a-zA-Z]{2,4}$/;
    const passwordPattern = /(?=.*\d)(?=.*[a-zA-Z]).{8,}/;

    if (isNotSign && specialRegex.test(str)) {
      setErrors({ ...errors, [fieldName]: "Don't include @ signs." });
    } else if (isNotEmoji && emojiRegex.test(str)) {
      setErrors({ ...errors, [fieldName]: "Don't include emojis." });
    } else if (isNotWhiteSpace && /\s/.test(str)) {
      setErrors({ ...errors, [fieldName]: "Don't include white spaces." });
    } else if (isNotNumber && numberRegex.test(str)) {
      setErrors({ ...errors, [fieldName]: "Don't include numbers." });
    } else if (fieldName === 'email' && !emailPattern.test(str)) {
      setErrors({
        ...errors,
        [fieldName]: 'You have entered an invalid email address.',
      });
    } else if (fieldName === 'password' && !passwordPattern.test(str)) {
      setErrors({
        ...errors,
        [fieldName]:
          'Must contain at least one number and one letter, and at least 8 or more characters',
      });
    } else {
      setErrors({ ...errors, [fieldName]: '' });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGymChange = (event) => {
    setGym(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Object.keys(errors).filter((key) => !!errors[key]).length > 0) {
      return;
    }

    const data = new FormData(event.currentTarget);
    const payload = {
      displayName: data.get('username'),
      userName: data.get('username'),
      password: data.get('password'),
      email: data.get('email'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      birthdate: '',
      country: "United States",
      gender: '',
      gymId: gym,
      inviteCode: data.get('invite-code'),
      signUpForEmails: !!data.get('signUpForEmails'),
      mktgSource: "Website",
      mainGoal: data.get("mainGoal") || "N/A",
      testUser: false,
      phone: "",
      weight: 0,
      action: "registerNewPlayer",
    };
    

    setIsSubmit(true);

    callBackbonePostApi(payload)
      .then((data) => {
        setIsSubmit(false);
        if (data.status === 200) {
          const submitError = data.data.error;

          if (submitError) {
             if (submitError === 'Username already exists') {
              setErrors({
                ...errors,
                username: 'That username is already taken. Please choose a new one.',
              });
            } else if (submitError === 'Email already in use') {
              setErrors({
                ...errors,
                email:
                  'This email address has already been used. If you already have an account, log in to the Black Box VR app.',
              });
            }
          } else {
            event.target.reset();

            if (isScheduling) {
              navigate(`/gymmaster/scheduling/${data.data.userId}`);
            } else {
              setOpenSuccessModal(true);
            }
          }
        }
      })
      .catch(() => {
        setIsSubmit(false);

        enqueueSnackbar('Submit failed! Please try later again.', {
          variant: 'error',
        });
      });
  };

  const onContinue = () => {
    setOpenSuccessModal(false);
  };

  return (
    <Container
      component="main"
      sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 450,
          p: 3,
          border: '1px solid white',
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LocalImage src={`img/logo-mark.png`} width="100%" height="100%" alt="" loading="lazy" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Get Started
        </Typography>
        <Box component="form" validate="true" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                inputProps={{ maxLength: 25 }}
                onInput={(e) => onValidate('firstName', e.target.value, true, true, false, true)}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                inputProps={{ maxLength: 25 }}
                onInput={(e) => onValidate('lastName', e.target.value, true, true, false, true)}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                inputProps={{ minLength: 3, maxLength: 25 }}
                onInput={(e) => onValidate('username', e.target.value, true, true, true)}
                error={!!errors.username}
                helperText={errors.username}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                required
                sx={{ width: '100%' }}
                variant="outlined"
                error={!!errors.password}
              >
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  inputProps={{
                    minLength: 8,
                    maxLength: 50,
                    title:
                      'Must contain at least one number and one letter, and at least 8 or more characters',
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  onInput={(e) => onValidate('password', e.target.value, true)}
                />
                {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                onInput={(e) => onValidate('email', e.target.value, true)}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl required fullWidth>
                <InputLabel id="gym-select-label">Gym</InputLabel>
                <Select
                  labelId="gym-select-label"
                  id="gym-select"
                  defaultValue={''}
                  value={gym}
                  label="Gym"
                  onChange={handleGymChange}
                >
                  <MenuItem value="14">Black Box VR - Meridian</MenuItem>
                  <MenuItem value="1">Black Box VR - San Francisco</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="invite-code"
                label="VIP Invite Code (Optional)"
                name="invite-code"
                autoComplete="invite-code"
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={3}>
            <FormControlLabel
              control={
                <Checkbox value="join" name="signUpForEmails" color="primary" defaultChecked />
              }
              label="Join our community and receive exclusive content and special offers!"
            />
          </Grid>
          <CustomButton
            type="submit"
            fullWidth
            variant="contained"
            loading={isSubmit}
            sx={{ mt: 3, mb: 2 }}
          >
            Get Started
          </CustomButton>
          {/* <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Modal
        open={openSuccessModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: 'green' }}>
            Thank you!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, color: 'grey' }}>
            Congratulations, your account has been successfully created.
          </Typography>
          <CustomButton sx={{ mt: 3 }} onClick={onContinue}>
            Continue
          </CustomButton>
        </Box>
      </Modal>
    </Container>
  );
};

export default SignUp;
