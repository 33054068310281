import Container from '../../../../components/Layout/Container';
import { Heading, Heading1 } from '../../../../components/Heading';
import { BBVR_FILE_URL } from '../../../../config/constants';
import { Box, Grid } from '@mui/material';
import S3Avatar from '../../../../components/S3Avatar';

const PreRaidHighlights = ({
  mostUsedExercise,
  leastUsedExercise,
  mostUsedChampion,
  mostUsedPowerup
}) => {
  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1 sx={{ px: { xs: 2, md: 0 } }}>Highlights</Heading1>
      <Grid container spacing={1} sx={{
        justifyContent: "center",
        marginTop: 6,
        padding: "25px 16px 25px 16px",
        backgroundColor: "#141516"
      }}>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" flexDirection="column" sx={{ alignItems: "center" }}>
          <Heading size={30} sx={{ mb: 2, color: '#D4D4D4' }}>Most Used Exercise</Heading>
          <Box flexGrow={1} display="flex" alignItems="flex-start" pb={4}>
            <Box position="relative" borderRadius="24px" border="2px solid #F1F1F1" sx={{ padding: "0px 1px 2px", boxShadow: "0px 11.598px 11.598px 0px rgba(0, 0, 0, 0.25)" }}>
              <S3Avatar
                src={`${BBVR_FILE_URL}/ExerciseImages/1024x1024/WEBP/${mostUsedExercise?.inGameName}.webp`}
                width="175px"
                height="215px"
                sx={{
                  border: "2px solid white",
                  borderRadius: "24px"
                }}
              />
              <Box display="flex" alignItems="center" justifyContent="center" position="absolute" height="64px" sx={{
                width: "calc(100% - 8px)",
                borderRadius: "24px",
                background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 70.17%)",
                bottom: "4px",
                left: "4px"
              }}>
                <Heading
                  size={{ xs: 16, tl: 24, xl: 30 }}
                  sx={{
                    textShadow: "0px 5.799px 2.9px #000",
                    fontWeight: 450,
                    color: "white",
                  }}
                >{mostUsedExercise?.name}</Heading>
              </Box>
            </Box>
          </Box>
          <Heading size={24} sx={{ color: '#D4D4D4' }}>{mostUsedExercise?.useCount || 0} Sets</Heading>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" flexDirection="column" sx={{ alignItems: "center" }}>
          <Heading size={30} sx={{ mb: 2, color: '#D4D4D4' }}>Least Used Exercise</Heading>
          <Box flexGrow={1} display="flex" alignItems="flex-start" pb={4}>
            <Box position="relative" borderRadius="24px" border="2px solid #F1F1F1" sx={{ padding: "0px 1px 2px", boxShadow: "0px 11.598px 11.598px 0px rgba(0, 0, 0, 0.25)" }}>
              <S3Avatar
                src={`${BBVR_FILE_URL}/ExerciseImages/1024x1024/WEBP/${leastUsedExercise?.inGameName}.webp`}
                width="175px"
                height="215px"
                sx={{
                  border: "2px solid white",
                  borderRadius: "24px"
                }}
              />
              <Box display="flex" alignItems="center" justifyContent="center" position="absolute" height="64px" sx={{
                width: "calc(100% - 8px)",
                borderRadius: "24px",
                background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 70.17%)",
                bottom: "4px",
                left: "4px"
              }}>
                <Heading
                  size={{ xs: 16, tl: 24, xl: 30 }}
                  sx={{
                    textShadow: "0px 5.799px 2.9px #000",
                    fontWeight: 450,
                    color: "white"
                  }}
                >{leastUsedExercise?.name}</Heading>
              </Box>
            </Box>
          </Box>
          <Heading size={24} sx={{ color: '#D4D4D4' }}>{leastUsedExercise?.useCount || 0} Sets</Heading>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" flexDirection="column" sx={{ alignItems: "center" }}>
          <Heading size={30} sx={{ mb: 2, color: '#D4D4D4' }}>Most Used Champion</Heading>
          <Box flexGrow={1} display="flex" alignItems="flex-start" pb={4}>
            <Box position="relative" borderRadius="24px" border="2px solid #F1F1F1" sx={{ padding: "0px 1px 2px", boxShadow: "0px 11.598px 11.598px 0px rgba(0, 0, 0, 0.25)" }}>
              <S3Avatar
                src={`${BBVR_FILE_URL}/ChampionImages/1024x1024/WEBP/${mostUsedChampion?.shortCode}.webp`}
                width="175px"
                height="215px"
                sx={{
                  border: "2px solid white",
                  borderRadius: "24px"
                }}
              />
              <Box display="flex" alignItems="center" justifyContent="center" position="absolute" height="64px" sx={{
                width: "calc(100% - 8px)",
                borderRadius: "24px",
                background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 70.17%)",
                bottom: "4px",
                left: "4px"
              }}>
                <Heading
                  size={{ xs: 16, tl: 24, xl: 30 }}
                  sx={{
                    textShadow: "0px 5.799px 2.9px #000",
                    fontWeight: 450,
                    color: "white"
                  }}
                >{mostUsedChampion?.name}</Heading>
              </Box>
            </Box>
          </Box>
          <Heading size={24} sx={{ color: '#D4D4D4' }}>Deployed {mostUsedChampion?.useCount || 0} Times</Heading>
        </Grid>
        <Grid item xs={12} sm={6} lg={3} justifyContent="center" display="flex" flexDirection="column" sx={{ alignItems: "center" }}>
          <Heading size={30} sx={{ mb: 2, color: '#D4D4D4' }}>Most Used Powerup</Heading>
          <Box position="relative" flexGrow={1} display="flex" alignItems="flex-start" pb={4}>
            <Box borderRadius="24px" border="2px solid #F1F1F1" sx={{ padding: "0px 1px 2px", boxShadow: "0px 11.598px 11.598px 0px rgba(0, 0, 0, 0.25)" }}>
              <S3Avatar
                src={`${BBVR_FILE_URL}/PowerupImages/1024x1024/WEBP/${mostUsedPowerup?.shortCode || 'PA01'}.webp`}
                width="175px"
                height="215px"
                sx={{
                  border: "2px solid white",
                  borderRadius: "24px"
                }}
              />
              {mostUsedPowerup?.name && (
                <Box display="flex" alignItems="center" justifyContent="center" position="absolute" height="64px" sx={{
                  width: "calc(100% - 8px)",
                  borderRadius: "24px",
                  background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 70.17%)",
                  bottom: "4px",
                  left: "4px"
                }}>
                  <Heading
                    size={{ xs: 16, tl: 24, xl: 30 }}
                    sx={{
                      textShadow: "0px 5.799px 2.9px #000",
                      fontWeight: 450,
                      color: "white"
                    }}
                  >{mostUsedPowerup?.name}</Heading>
                </Box>
              )}
            </Box>
          </Box>
          <Heading size={24} sx={{ color: '#D4D4D4' }}>Used {mostUsedPowerup?.useCount || 0} Times</Heading>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PreRaidHighlights;
