import { Grid, Typography } from "@mui/material"
import { Heading2 } from "../../../components/Heading"
import Container from "../../../components/Layout/Container"
import PreRaidRecapQuestItem from "./QuestItem";

const PreRaidTeamRecapTopPerformers = ({
  members,
  teamQuests
}) => {
  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading2 sx={{ px: { xs: 2, md: 0 } }}>TEAM SHARED QUEST TOP PERFORMERS</Heading2>
      <Typography mt={1.5} fontSize={{ xs: 14, tl: 16, md: 20, xl: 24 }} lineHeight="32px" sx={{ px: { xs: 2, md: 0 } }}>
        Complete objectives as a team to earn Boost Tokens which <br />unlock valuable Arena Raid Boost items!
      </Typography>

      <Grid container mt={3} spacing={3} sx={{ px: { xs: 2, md: 0 } }}>
        {teamQuests.map(quest => (
          <Grid item key={quest?.shortCode} xs={12} sm={6} lg={4}>
            <PreRaidRecapQuestItem members={members} quest={quest} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default PreRaidTeamRecapTopPerformers;
