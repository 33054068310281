import { Box, Typography } from "@mui/material";
import { Heading1 } from "../../../../components/Heading";
import Container from "../../../../components/Layout/Container";

const PreRaidTipsForSuccess = () => {
  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1 sx={{ px: { xs: 2, md: 0 } }}>Pre-Raid Tips For Success</Heading1>
      <Box maxWidth="916px" mt={3} px={{ xs: 2, md: 0 }}>
        <Typography>
          During this period, teams focus on building their fitness, developing strategies, and maximizing their chances of success in the intense Raid battles that lie ahead.
        </Typography>
        <Typography fontWeight={700} py={3}>
          Team Goal: Unlock Arena Raid Boost Items!
        </Typography>
        <Typography>
          Boost items give your team an advantage in the upcoming Arena Raid. The more you can unlock together, the more power you will have to defeat Challengers and earn valuable Raid Points. Arena Raid Boost items are unlocked with Team Boost Tokens. Earn Boost Tokens by collectively completing team shared quests. The more you complete, the more Boost Tokens you will earn and the more Boost items you’ll unlock!
        </Typography>
        <Typography fontWeight={700} py={3}>
          Tips for Team Success in the Arena Raid:
        </Typography>
        <Typography>
          1. Fill every open spot in your team. A team of 12 will be able to complete many more shared quests!
          <br />
          2. Make sure every team member is active and completing at least 3 workouts per week.
          <br />
          3. Strategize in the team chat and focus on the shared quest objectives.
        </Typography>
        <Typography mt={3}>
          The pre-Raid period isn’t just preparation—it’s the foundation for success in the intense, rewarding Arena Raids.
        </Typography>
      </Box>
    </Container>
  )
}

export default PreRaidTipsForSuccess;
