import { useEffect, useCallback, useState } from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AnimatedPopup = ({ children, description, placement = 'bottom-end' }) => {
  const [anchor, setAnchor] = useState(null);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box
        ref={setAnchor}
        onClick={() => setOpen((o) => !o)}
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', '& svg': { ml: 1 } }}
      >
        {children}
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Box>
      <Popup anchor={anchor} open={open} withTransition placement={placement} style={{ zIndex: 10 }}>
        {(props) => (
          <PopAnimation {...props}>
            <PopupBody dangerouslySetInnerHTML={{ __html: description }} />
          </PopAnimation>
        )}
      </Popup>
    </div>
  );
};

export default AnimatedPopup;

function Animated(props) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div
      onAnimationEnd={handleAnimationEnd}
      className={className + (requestOpen ? ' open' : ' close')}
    >
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const PopupBody = styled('div')(({ theme }) => ({
  backgroundColor: '#1E1E1E',
  maxWidth: 758,
  padding: theme.spacing(4),
  marginTop: theme.spacing(1),
  borderRadius: theme.spacing(1),
  lineHeight: 1.5,
}));
