import { Box, Stack, Typography } from "@mui/material";
import LocalImage from "../../../components/LocalImage";

const RaidBoostItemUnlockedCard = ({
  boostItem
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      p={1}
      height="calc(100% - 16px)"
      bgcolor="#14AE5C"
      borderRadius="8px"
      sx={{
        opacity: 0.8
      }}
    >
      <Box display="flex" gap={1}>
        <LocalImage
          src={`img/team/Neon Lightning Bolt.webp`}
          alt={`Neon Lightning Bolt`}
          loading="lazy"
          width="72px"
          height="72px"
          style={{
            borderRadius: "4px"
          }}
        />
        <Stack spacing={0.5}>
          <Typography
            color="#FFF"
            fontSize={14}
            fontWeight={700}
            lineHeight="16px"
            textTransform="uppercase"
            sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
          >
            {boostItem?.title}
          </Typography>
          <Typography
            color="#FFF"
            fontSize={14}
            fontWeight={400}
            lineHeight="16px"
            textTransform="uppercase"
            sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
          >
            Level {boostItem?.level || 0} of {boostItem?.maxLevel || 0}
          </Typography>
        </Stack>
      </Box>
      <Box borderTop="1px solid #FFF" borderBottom="1px solid #FFF">
        <Typography
          color="#FFF"
          fontSize={14}
          fontWeight={500}
          sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
        >
          Effect: {boostItem?.effectValue || 0}{boostItem?.shortCode === "SidekickPlayerFirehawkUseIncrease" ? " Times" : (boostItem?.effectType === "LevelUp" || boostItem?.effectType === "LevelDown") ? " Level" : boostItem?.effectType === "Multiplier" || boostItem?.effectType === "Reduction" ? "x" : "%"}
          {boostItem?.effectType === "Multiplier" || boostItem?.effectType === "LevelUp" ? " Increase" : boostItem?.effectType === "LevelDown" ? " Decrease" : " Reduction"}!
        </Typography>
      </Box>
      <Box>
        <Typography
          color="#FFF"
          fontSize={14}
          fontWeight={400}
          lineHeight="16px"
          sx={{ textShadow: " 0px 0.832px 0.832px rgba(0, 0, 0, 0.25)" }}
        >
          {boostItem?.description}
        </Typography>
      </Box>
    </Box>
  );
}

export default RaidBoostItemUnlockedCard;
