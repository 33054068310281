import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { formatCompactNumber } from '../../../../utils';

import { Heading2, Heading3, Heading4 } from '../../../../components/Heading';
import S3Avatar from '../../../../components/S3Avatar';
import NumberMark from '../../../../components/NumberMark';

const GymLeaderboards = ({ hightLight, title, data, type, valueKey = 'value' }) => {

  return (
    <Box>
      <Heading3>{title}</Heading3>
      <Box mt={{ xs: 3, tl: 4, lg: 7 }}>
        {data.map((obj, index) => (
          <React.Fragment key={index}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={3}
              pl={{ xs: 2, lg: 0 }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  position="relative"
                  width={
                    index === 0 ? (hightLight ? { xs: 128, tl: 248 } : { xs: 88, tl: 96 }) : 64
                  }
                  height={
                    index === 0 ? (hightLight ? { xs: 128, tl: 248 } : { xs: 88, tl: 96 }) : 64
                  }
                >
                  <NumberMark number={index + 1} />
                  <Link to={`/${type}/${obj.teamName}`}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        padding: '1px',
                        background:
                          index === 0
                            ? 'linear-gradient(180deg, #925F01 0%, #C3A128 30%, #FEF89D 40%, #F4CE02 80%)'
                            : '#313131',
                      }}
                    >
                      <S3Avatar src={obj.teamLogoUrl} />
                    </Box>
                  </Link>
                </Box>
                {!(index === 0 && hightLight) && (
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Link to={`/${type}/${obj.teamName}`}>
                      <Heading4 sx={{ ml: index === 0 ? 2 : 3, wordBreak: 'break-all' }}>
                        {obj.teamName}
                      </Heading4>
                    </Link>
                    <Link to={`/member/${obj.captainUsername}`}>
                      <Typography sx={{ ml: index === 0 ? 2 : 3, wordBreak: 'break-all' }}>
                        Captain: {obj.captainUsername}
                      </Typography>
                    </Link>
                  </Box>
                )}
              </Box>
              <Box flex={index === 0 && hightLight ? 1 : 0} ml={index === 0 && hightLight ? 3 : 0}>
                {index === 0 && hightLight && (
                  <Link to={`/${type}/${obj.teamName}`}>
                    <Heading3 sx={{ ml: 1, mb: 2, wordBreak: 'break-all' }}>{obj.teamName}</Heading3>
                  </Link>
                )}
                <Heading2
                  sx={{ ml: 1, ...(index === 0 && hightLight && { fontSize: { xs: 40, tl: 80 } }) }}
                >
                  {formatCompactNumber(obj?.[valueKey])}
                </Heading2>
              </Box>
            </Box>
            {index !== data.length - 1 && (
              <Box display="flex" justifyContent="flex-end">
                <Divider
                  sx={{
                    borderColor: '#B1B1B1',
                    mt: 2,
                    width: { xs: '100%', tl: 'calc(100% - 88px)' },
                  }}
                />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default GymLeaderboards;
